import React from "react";

import { prefix } from "../components/offer-section.utils";
import Layout from "../components/layout";
import SEO from "../components/seo";

const background = "#20222d";

const NotFound = () => (
  <Layout>
    <SEO
      title="Rolety Lider | 404"
      description="Profesjonalny montaz rolet na terenie Krakow i okolic. 25 lat doświadczenia - materiały od sprawdzonych producentów."
    />
    <section style={{ marginTop: "-6.1rem" }}>
      {prefix(true, background)}
      <article
        style={{
          background,
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "uppercase"
        }}
      >
          link nie istnieje
      </article>
    </section>
  </Layout>
);

export default NotFound;
